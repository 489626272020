<template>
    <div>
        <div class="header">
            <div class="left">
                <van-icon name="arrow-left" @click="goback()" class="icon" />
            </div>
            <div class="title">登录/注册</div>
            <div>
                <van-icon size="23" name="wap-home-o" @click="$router.push('/')" style="margin-right:10px"/>
            </div>
        </div>
        <div class="login-box">
            <div class="logo"></div>
            <div class="input-box">
                <input v-model="mobile" placeholder="手机号" />
            </div>
            <div class="input-box" v-show="loginType == 1">
                <input v-model="password" type="password" placeholder="密码" />
            </div>
            <div class="f input-box" v-show="loginType == 2">
                <input v-model="smsCode" placeholder="验证码" class="f-auto" /><span class="btn-code" :class="{ disabled: isCountDown }" @click="handleSend">{{ codeText }}</span>
            </div>

            <div class="login-gourl">
                <div class="switch-margin-left" @click="loginType = loginType == 1 ? 2 : 1">
                    切换{{ loginType == 1 ? "验证码" : "密码" }}登录
                </div>
                <div class="switch-margin-right" @click="getPassWord">找回密码?</div>
            </div>

            <div class="btn-submit" @click="onSubmit">登录/注册</div>
            <div class="logo-continer">
                <!-- <img @click="wxLogin" class="wx-loge" src="@/assets/images/wx.jpeg" /> -->
            </div>

            <div class="f-cc agree">
                <van-checkbox v-model="agree" shape="square">同意并签署</van-checkbox>
                <span class="m-l-10 c-5F7CFD" @click="handleAgreement">
                    <span @click="gopath('/me/detail', '用户协议')"> 《用户协议》</span>
                    <span class="color_text">与</span>
                    <span @click="gopath('/me/detail', '隐私政策')">《隐私政策》</span>
                </span>
            </div>
            <van-dialog v-model:show="showAgreement">
                <div v-html="agreementText" class="agreement"></div>
            </van-dialog>
        </div>
    </div>
</template>

<script>
import {
    mobile_password,
    mobile_send,
    smscode_login,
    getuserinfo,
} from "@/api/login.js";

import { wx_getOpenId, wx_getCode, wx_login } from "@/api/wxLogin.js";

import { is_weixin } from "@/utils/utils.js";
export default {
    data() {
        return {
            mobile: "", //13811362165    18410272572
            loginType: 2,
            password: "",
            isCountDown: true,
            agree: false,
            showAgreement: false,
            codeText: "获取验证码",
            agreementText: "",
            smsCode: "",
            regMobile: /^1[3456789]\d{9}$/,
            backpage: "",
            // redirectUrl:'',
        };
    },
    name: "login",
    created() {
        
        // if(this.$route.query.url){
        //     this.redirectUrl = this.$route.query.url
        // }
    },
    methods: {
        wxLogin() {
            //wx4a8dae365b168bfe   wx7f4b3784986dcb47
            let url = "http://saen.com/";
            if (is_weixin()) {
                window.location.href =
                    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx7f4b3784986dcb47&redirect_uri=" +
                    encodeURIComponent(url) +
                    "&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect";
            } else {
                this.$toast("请在微信客户端打开");
            }
        },
        getPassWord() {
            this.$router.push("retpassword");
        },
        userInfo() {
            getuserinfo().then((res) => {
                if (res.data.code == 0) {
                    localStorage.setItem("userInfo", JSON.stringify(res.data));
                    let replaceUrl = localStorage.getItem("replaceUrl") || "";
                    // alert(
                    //     replaceUrl.indexOf("login") == -1,
                    //     "replaceUrl",
                    //     replaceUrl
                    // );
                    if (replaceUrl.indexOf("login") != -1) {
                        this.$router.push("/");
                    } else {
                        if (replaceUrl) {
                            window.location.replace(replaceUrl);
                            localStorage.removeItem("replaceUrl");
                        } else {
                            this.$router.push("/");
                        }
                    }
                }
            });
        },
        onSubmit() {
            if (!this.agree) {
                this.$dialog
                    .alert({
                        message: "请阅读并同意《用户服务协议》和《隐私政策》",
                    })
                    .then(() => {
                        this.agree = true;
                    });
                return;
            }

            let errHint = "";
            if (!this.regMobile.test(this.mobile)) {
                errHint = this.mobile ? "手机号格式有误" : "请填写手机号";
            } else if (this.loginType == 2) {
                if (!this.smsCode) {
                    errHint = "请输入验证码";
                }
            } else if (!this.password) {
                errHint = "请输入密码";
            }
            if (errHint) {
                this.$toast(errHint);
                return;
            }
            if (this.loginType == 2) {
                smscode_login(this.mobile, this.smsCode).then((res) => {
                    console.log("res", res);
                    if (res.data.code == 0) {
                        localStorage.setItem("tokenSaen", res.data.data.token);
                        this.userInfo();
                    } else {
                        this.$toast(res.data.msg);
                    }
                });
            } else {
                mobile_password(this.mobile, this.password).then((res) => {
                    if (res.data.code == 0) {
                        localStorage.setItem("tokenSaen", res.data.data.token);
                        this.userInfo();
                    } else {
                        this.$toast(res.data.msg);
                    }
                });
            }
        },
        handleSend() {
            if (this.regMobile.test(this.mobile)) {
                mobile_send({
                    mobile: this.mobile,
                    type: 2,
                })
                    .then((res) => {
                        this.isCountDown = true;
                        this.countDown(60);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.$toast(this.mobile ? "手机号格式有误" : "请填写手机号");
            }
        },
        handleAgreement() { },
        countDown(num) {
            if (num > 0) {
                this.codeText = `${num}秒后重发`;
                num--;
                setTimeout(() => {
                    this.countDown(num);
                }, 1000);
            } else {
                this.isCountDown = false;
                this.codeText = "重发发送";
            }
        },
        gopath(path, val) {
            this.$router.push({ path: path, query: { title: val } });
        },
        goback() {
          if (this.backpage == "home") {
            this.$router.replace('/');
          } else {
            this.$router.replace('/');
          }
        },
    },
    mounted() {
        this.$toast.clear();
     },
    // 路由监听
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // vm相当于上面的this
            console.log(from.path, to); 
            if (from.path == "/") {
                vm.backpage = "home";
            }
            //   if (from.path == "/" && sessionStorage.getItem("orderpayFlag") == "1") {
            //     vm.gopath("/orderstate");
            //   }
            //   vm.fromPage = from.path;
        })
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.header {
    padding: 10px 10px;
    display: flex;
    .left {
        width: 10%;
        .icon {
            font-size: 24px;
            font-weight: 600;
            color: #141414;
        }
    }
    .title {
        width: 85%;
        text-align: center;
        font-size: 17px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141414;
    }
}
body {
    background-color: #fff;
}
.login-box {
    padding: 0 33px;
}
.login-gourl {
    height: 45px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.logo-continer {
    width: 100%;
    height: 150px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.switch-margin-right {
    margin-right: 40px;
    color: #888888;
    margin-bottom: 0.4rem;
}
.switch-margin-left {
    margin-left: 40px;
    color: #333;
    margin-bottom: 0.4rem;
}
.wx-loge {
    width: 32px;
    height: 32px;
}
.logo {
    font-weight: 500;
    font-size: 16px;
    color: #141414;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 40px;
    padding-top: 75px;
    background: url(../../assets/all_Icon/drawable-xxhdpi/icon_login_logo.png)
        no-repeat center top;
    background-size: 168px;
}
.switch {
    color: #333;
    margin-bottom: 0.4rem;
    margin-left: 0.15rem;
}
.input-box {
    line-height: 22px;
    border-radius: 22px;
    margin-bottom: 20px;
    padding: 10px 26px;
    border: 1px solid #ccc;
}
.btn-code {
    color: #5d7dff;
}
.btn-submit {
    color: #fff;
    font-size: 17px;
    line-height: 50px;
    text-align: center;
    border-radius: 50px;
    background-color: #5d7dff;
    margin-top: 10px;
}
.agree {
    width: 100%;
    font-size: 14px;
    margin-left: -20px;
    color: #666;
    margin: 0 auto;
}
.agreement {
    padding: 10px;
    height: 70vh;
    overflow: auto;
}
.color_text {
    color: #333;
}
</style>
